/**
 * @file ReadSpeaker-Konfiguration
 * @author <a href="mailto:heiko.pfefferkorn@ifabrik.de">Heiko Pfefferkorn</a>
 * @copyright i-fabrik GmbH 2019
 */

/* global window:true */

window.rsConf = {
	'general' : {
		'cookieName'           : 'SportlandBildungReadSpeaker',
		// 'usePost'   : true,
		'useCompactPopupButton': 1,
		'popupCloseTime'       : 2000
	},
	'phrases' : {
		'de_de': {
			'loaderror': 'Fehler'
		},
		'en'   : {
			'loaderror': 'Error'
		}
	},
	'settings': {
		// 'hltoggle': 'hloff',
		'hlicon'  : 'iconon',
		'hlscroll': 'scrolloff',
		// 'hltext'  : '#cf0b48',
		// 'hlsent'  : '#000000'
	},
	'ui'      : {
		// 'disableDetachment': true,
		// 'enlarge'          : false,
		// 'readhover'        : false,
		// 'rsbtnClass'       : 'rspeak-custom__btn',
		'tools': {
			// 'enlarge'      : false,
			// 'help'         : false,
			'pagemask' : false,
			'readhover': false
			// 'simpleview'   : false,
			// 'voicesettings': false
		}//,
		// 'player'           : [
		// 	'<span class="rsbtn_exp_inner c-rspeak__exp-inner">',
		// 	'<button type="button" class="rsbtn_pause rsbtn_player_item" title="Pause"><i class="rsicn rsicn-pause" aria-hidden="true"></i><span class="rsbtn_btnlabel">Pause</span></button>',
		// 	'<button type="button" class="rsbtn_stop rsbtn_player_item" title="Stop"><i class="rsicn rsicn-stop" aria-hidden="true"></i><span class="rsbtn_btnlabel">Stop</span></button>',
		// 	'<span class="rsbtn_progress_container rsbtn_player_item rsloading">',
		// 	'<span class="rsbtn_progress_played"></span>',
		// 	'</span>',
		// 	'<button type="button" class="rsbtn_volume rsbtn_player_item" title="Volume" aria-expanded="false"><i class="rsicn rsicn-volume" aria-hidden="true"></i><span class="rsbtn_btnlabel">Volume</span></button>',
		// 	'<span class="rsbtn_volume_container">',
		// 	'<span class="rsbtn_volume_slider"></span>',
		// 	'</span>',
		// 	'<button type="button" class="rsbtn_dl rsbtn_player_item" title="Download MP3"><i class="rsicn rsicn-download" aria-hidden="true"></i><span class="rsbtn_btnlabel">Download mp3</span></button>',
		// 	'<button type="button" class="rsbtn_settings rsbtn_player_item" title="Settings" data-phrase="ph_settings" accesskey="s"><i class="rsicn rsicn-cogs" aria-hidden="true"></i><span class="rsbtn_btnlabel">Einstellungen</span></button>',
		// 	'<button type="button" class="rsbtn_closer rsbtn_player_item" title="Close Player"><i class="rsicn rsicn-cross" aria-hidden="true"></i><span class="rsbtn_btnlabel">Close</span></button>',
		// 	'</span>'
		// ],
		//
		// player  : [
		// 	'<span class="rsbtn_exp_inner ">',
		// 		'<button type="button" class="rsbtn_pause rsbtn_player_item" title="Pause"><i class="rsicn rsicn-pause" aria-hidden="true"></i><span class="rsbtn_btnlabel">Pause</span></button>',
		// 		'<button type="button" class="rsbtn_stop rsbtn_player_item" title="Stop"><i class="rsicn rsicn-stop" aria-hidden="true"></i><span class="rsbtn_btnlabel">Stop</span></button>',
		// 		'<span class="rsbtn_progress_container rsbtn_player_item rsloading"><span class="rsbtn_progress_played"></span></span>',
		// 		'<button type="button" class="rsbtn_volume rsbtn_player_item" title="Volume" aria-expanded="false"><i class="rsicn rsicn-volume" aria-hidden="true"></i><span class="rsbtn_btnlabel">Volume</span></button>',
		// 		'<span class="rsbtn_volume_container"><span class="rsbtn_volume_slider"></span></span>',
		// 		'<button type="button" class="rsbtn_dl rsbtn_player_item" title="Download MP3"><i class="rsicn rsicn-download" aria-hidden="true"></i><span class="rsbtn_btnlabel">Download mp3</span></button>',
		// 		'<button type="button" class="rsbtn_closer rsbtn_player_item" title="Close Player"><i class="rsicn rsicn-cross" aria-hidden="true"></i><span class="rsbtn_btnlabel">Close</span></button>',
		// 	'</span>'
		// ]
		// 'player31'         : [
		// 	'<span class="rsbtn_exp_inner c-rspeak__exp-inner">',
		// 	'<button type="button" class="rsbtn_pause rsbtn_player_item" title="Pause"><i class="rsicn rsicn-pause" aria-hidden="true"></i><span class="rsbtn_btnlabel">Pause</span></button>',
		// 	'<button type="button" class="rsbtn_stop rsbtn_player_item" title="Stop"><i class="rsicn rsicn-stop" aria-hidden="true"></i><span class="rsbtn_btnlabel">Stop</span></button>',
		// 	'<span class="rsbtn_progress_container rsbtn_player_item rsloading"><span class="rsbtn_progress_played"></span></span>',
		// 	'<button type="button" class="rsbtn_rewind rsbtn_player_item" title="Skip backwards"><i class="rsicn rsicn-backward2" aria-hidden="true"></i><span class="rsbtn_btnlabel">Skip backwards</span></button>',
		// 	'<button type="button" class="rsbtn_forward rsbtn_player_item" title="Skip forwards"><i class="rsicn rsicn-forward3" aria-hidden="true"></i><span class="rsbtn_btnlabel">Skip forwards</span></button>',
		// 	'<button type="button" class="rsbtn_volume rsbtn_player_item" title="Volume" aria-expanded="false"><i class="rsicn rsicn-volume" aria-hidden="true"></i><span class="rsbtn_btnlabel">Volume</span></button>',
		// 	'<span class="rsbtn_volume_container rsbtn_dropdown"><span class="rsbtn_volume_slider rsbtn_slider"></span></span>',
		// 	'<button type="button" class="rsbtn_speed rsbtn_player_item" title="Speed" aria-expanded="false"><i class="rsicn rsicn-meter"></i><span class="rsbtn_btnlabel">Speed</span></button>',
		// 	'<span class="rsbtn_speed_container rsbtn_dropdown"><span class="rsbtn_speed_slider rsbtn_slider"></span></span>',
		// 	'<button type="button" class="rsbtn_dl rsbtn_player_item" title="Download MP3"><i class="rsicn rsicn-download" aria-hidden="true"></i><span class="rsbtn_btnlabel">Download mp3</span></button>',
		// 	'<button type="button" class="rsbtn_closer rsbtn_player_item" title="Close Player"><i class="rsicn rsicn-cross" aria-hidden="true"></i><span class="rsbtn_btnlabel">Close</span></button>',
		// 	'</span>'
		// ]
	}
};
